import React from "react"
import AboutTwo from "../components/about-two"
import RecentProject from "../components/recent-project"
import CallToActionTwo from "../components/call-to-action-two"
import AboutThree from "../components/about-three"
import ServiceTwo from "../components/service-two"
import HeaderOne from "../components/header-one"
import SliderOne from "../components/slider-one"
import MenuContextProvider from "../context/menu-context"
import Layout from "../components/layout"
import Footer from "../components/footer"

const Home = () => {
  return (
    <MenuContextProvider>
      <Layout PageTitle="Home">
        <HeaderOne />
        <SliderOne />
        <AboutTwo />
        <ServiceTwo />
        <CallToActionTwo />
        <AboutThree />
        <RecentProject />
        <Footer />
      </Layout>
    </MenuContextProvider>
  )
}

export default Home
