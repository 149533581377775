// export default SliderOne
import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Col, Container, Row } from "react-bootstrap"
import { SliderOneData } from "../data"
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper"

export default function ClientCarousel() {
  return (
    <>
      <section className="main-carousel">
        <Swiper
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          watchOverflow={true}
          loop={true}
          loopFillGroupWithBlank={true}
          navigation={true}
          modules={[Navigation, Autoplay]}
          className=""
        >
          {SliderOneData.map(({ image, subText, title, button }, index) => (
            <SwiperSlide key={index}>
              <img src={image} alt="Awesome Image" />
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
    </>
  )
}

//   return (
//     <section className="main-slider">
//       <Swiper {...mainSlideOptions}>
//         {SliderOneData.map(({ image, subText, title, button }, index) => (
//           <SwiperSlide key={index}>
//             <div
//               className="image-layer"
//               style={{
//                 backgroundImage: `url(${image})`,
//                 width: "100%",
//                 height: "100%",
//               }}
//             ></div>
//             <div className="thm-container">
//               <Row>
//                 <Col lg={12} className="text-center">
//                   <p className="main-slider__subtext">{subText}</p>
//                   <h3 className="main-slider__title">{title}</h3>
//                   {/* <Link to={button.url} className={`thm-btn`}>
//                     <span>{button.label}</span>
//                   </Link> */}
//                 </Col>
//               </Row>
//             </div>
//           </SwiperSlide>
//         ))}
//         {/* <div className="swiper-button-prev" id="main-slider-prev">
//           <i className="fa fa-angle-left"></i>
//         </div>
//         <div className="swiper-button-next" id="main-slider-next">
//           <i className="fa fa-angle-right"></i>
//         </div> */}
//       </Swiper>
//     </section>
//   )
// }
