import React from "react"
import { ProjectOneData } from "../data"
import { Col, Row } from "react-bootstrap"
import ProjectCard from "./project-card"

const RecentProject = () => {
  return (
    <section className="recent-projects sec-pad">
      <div className="thm-container">
        <div className="sec-title text-center">
          <span>Why Manjushree For</span>
          <h3>Printing and Publishing</h3>
          <p>
            Its been a privilage to work with various business cooperates and
            clients.
            <br /> What we offer is best quality at very best price.
          </p>
        </div>
        <Row>
          {ProjectOneData.slice(0, 3).map((post, index) => (
            <Col key={index} md={6} lg={4}>
              <ProjectCard data={post} />
            </Col>
          ))}
        </Row>
      </div>
    </section>
  )
}

export default RecentProject
