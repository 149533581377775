import { Link } from "gatsby"
import React from "react"
import serviceTwo1 from "../images/whatwedo.jpg"

const ServiceTwo = () => {
  return (
    <section className="what-we-do sec-pad">
      <div className="thm-container">
        <div className="sec-title text-center">
          <span>Our features</span>
          <h3>What We Do</h3>
          <p>
            There are many variations of passages of lorem Ipsum available, but
            <br /> the majority have suffered alteration in some form.
          </p>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="single-what-we-do">
              <div className="img-box">
                <img src={serviceTwo1} alt="" />
              </div>
              <div className="text-box hvr-bounce-to-bottom">
                <a href="/service-details">
                  <h3>All Kinds of Printing Service</h3>
                </a>
                <p>
                  We provide affordable printing at the highest quality with
                  competitive price. Our services includes all offset and
                  digital printing with graphic designing.
                </p>
                <Link
                  to="/services"
                  className="read-more fa fa-angle-right"
                ></Link>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="row">
              <div className="col-sm-6">
                <div className="single-what-we-do-one hvr-bounce-to-bottom">
                  <i className="printify-icon-paper-drill"></i>
                  <Link to="/services">
                    <h3>Digital Printing</h3>
                  </Link>
                  <p>
                    Our Digital Print includes Certificates, Brochures,
                    Calendar, PostCards, Greeting Cards, Catalog, Book,
                    Magazine, Wedding Card, Sticker, etc.
                  </p>
                  <Link
                    to="/services"
                    className="read-more fa fa-angle-right"
                  ></Link>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="single-what-we-do-one hvr-bounce-to-bottom">
                  <i className="printify-icon-printing-text"></i>
                  <Link to="/services">
                    <h3>Offset Printing</h3>
                  </Link>
                  <p>
                    Our Offset Print includes Pre-Ink Stamp, PAN Bill, VAT Bill,
                    PVC ID Card, Letterhead, Business Card, Envelope, Brochures,
                    etc
                  </p>
                  <Link
                    to="/services"
                    className="read-more fa fa-angle-right"
                  ></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ServiceTwo
